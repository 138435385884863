@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 22px;
}

body {
  min-height: 100vh;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

form {
  width: 100%;
  position: relative;
}

button {
  width: 33.33%;
  padding: 0.5rem;
  font-size: 1rem;
}

button.selected {
  background-color: #000;
  color: #fff;
}

ul {
  /* padding: 3rem 2rem 1rem; */
  margin-top: 1rem;
  padding: 0 2rem 1rem;
}

li {
  margin-bottom: 1rem;
}

.table-container {
  width: 100%;
  overflow-y: auto;
  padding-top: 52px;
}

td {
  border: 1px solid #000;
  padding: 0.25rem;
}
